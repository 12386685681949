import React from 'react'
import aboutText from '../../content/aboutText'
import { map } from 'ramda'
import styled from 'styled-components'
import { color1, color11, color10 } from '../../constants/colors'
import CirclePhoto from './CirclePhoto'
import MaxWidthContainer from '../Layout/MaxWidthContainer'

const Wrapper = styled.section`
 background-color: ${color1};
 padding-bottom: 20px;
`
const ContentContainer = styled.div`
 display: flex;
 padding-top: 25px;
`
const ParagraphsContaienr = styled.div`
`

const Paragraph = styled.p`
 margin: 0 0 15px 0;
 font-family: Roboto, sans-serif;
 font-weight: 400;
 font-size: 10px;
 text-align: justify;
 line-height: 140%;
 @media(min-width: 600px) {
  font-size: 12px;
 }
 @media(min-width: 1024px) {
  font-size: 20px;
 }
`

const AboutUs = styled.p`
  margin: 0 auto;
  padding-top: 20px;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 15px;
  @media(min-width: 600px) {
    font-size: 23px;
  }
    @media(min-width: 1024px) {
    font-size: 27px;
  }
`

const renderParagraphs = () =>
  <ParagraphsContaienr>
    {map((p) =>
      <Paragraph key={p}>{p}</Paragraph>
      , aboutText)}
  </ParagraphsContaienr>

const About = () => {
  return (
    <Wrapper>
      <MaxWidthContainer>
        <AboutUs>O mnie</AboutUs>
        <ContentContainer>
          <CirclePhoto />
          {renderParagraphs()}
        </ContentContainer>
      </MaxWidthContainer>
    </Wrapper >
  )
}

export default About
