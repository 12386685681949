import { Link } from 'gatsby';
import React from 'react';
import BackgroundImageDesktop from '../../images/background-desktop.jpg'
import BackgroundImageMobile from '../../images/background-mobile.jpg'
import { map, addIndex } from 'ramda'
import styled from 'styled-components'
import { color6, color11, color10 } from '../../constants/colors'
import { bottomHeaderText, topHeaderText } from '../../content/headerText'

const StyledHeader = styled.header`
  background: url(${BackgroundImageMobile}) no-repeat bottom center;
  @media(min-width: 600px){
    background: url(${BackgroundImageDesktop}) no-repeat bottom center;
    background-size: cover;
  }
  background-size: cover;
  height: 350px;
  position: relative;
  @media(min-width: 600px) {
    height: 450px;
  }
`
const Overlay = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  position: absolute;
  top: 3%;
  left: 3%;
  right: 3%;
  background-color: ${color11};
  padding: 10px 0;
`
const OverlayContent = styled.div`
  font-family: 'EB Garamond', serif;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;

  `
const HeaderTextWrapper = styled.div`
  margin: 0 auto;
  max-width: 90%;
  margin-bottom: 30px;
`

const StyledParagraph = styled.p`
  margin: 0;
  font-size: ${props => props.title ? '17px' : '15px'};
  text-transform: uppercase;
  color: ${color6};
  letter-spacing: 0.1em;
  line-height: 180%;
  text-shadow: 3px 3px 4px ${color10};
  @media(min-width: 600px) {
    font-size: ${props => props.title ? '28px' : '24px'};
  }
  @media(min-width: 1280px) {
    font-size: ${props => props.title ? '38px' : '32px'};
  }
`

const renderHeaderText = (textArr) => {
  return <HeaderTextWrapper>
    {map(p =>
      <StyledParagraph title={p === 'pedibello'} key={p}>{p}</StyledParagraph>
      , textArr)}
  </HeaderTextWrapper>
}

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <Overlay>
      <OverlayContent>
        {renderHeaderText(topHeaderText)}
        {renderHeaderText(bottomHeaderText)}
      </OverlayContent>
    </Overlay>
  </StyledHeader>
);

export default Header;
