import React from 'react'
import { color6, color7, color8, color14 } from '../../constants/colors'
import outOfContainer from '../Layout/outOfContainer'
import styled from 'styled-components'
import { prices, cautionText } from '../../content/pricing'
import { map, addIndex } from 'ramda'
import MaxWidthContainer from '../Layout/MaxWidthContainer'

const Wrapper = styled.div`
 background-color: ${color14};
`

const PriceList = styled.ul`
 list-style: none; 
 padding: 0;
`

const Item = styled.span`
  font-family: 'EB Garamond', serif;
  font-size: 9px;
  line-height: 30px;
  @media (min-width: 360px) {
   font-size: 10px;
  }
  @media (min-width: 375px) {
   font-size: 11px;
  }
  @media (min-width: 390px) {
   font-size: 12px;
   line-height: 2.5em;
  }
  @media (min-width: 600px) {
   font-size: 16px;
  }
  @media (min-width: 1024px) {
   font-size: 21px;
  }
`
const Price = styled.span`
  font-family: 'EB Garamond', serif;
  font-size: 12px;
  line-height: 2.5em;
  @media (min-width: 600px) {
   font-size: 16px;
  }
  @media (min-width: 1024px) {
   font-size: 21px;
  }
`
const PriceContainer = styled.div`
  display: inline-block;
  width: 70px;
  @media (min-width: 600px) {
   width: 90px;
  }
`

const Caution = styled.div`
  background-color: ${color8};
  padding: auto;
`

const CautionHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CautionHeader = styled.p`
  text-transform: uppercase;
  font-family: 'EB Garamond', serif;
  font-weight: 700;
  font-size: 10px;
  margin: 0;
  @media (min-width: 600px) {
   font-size: 16px;
  }
  @media (min-width: 1024px) {
   font-size: 26px;
  }
`

const CautionText = styled.p`
  font-family: 'EB Garamond', serif;
  font-size: 9px;
  @media (min-width: 600px) {
   font-size: 12px;
  }
  @media (min-width: 1024px) {
   font-size: 20px;
  }
`
const CautionSpace = styled.div`
  min-width: 5px;
  @media (min-width: 600px) {
   min-width: 100px;
  }
`

const CautionContentContainer = styled.div`
  display: flex;
  aling-content: space-between;
`

const PricingHeader = styled.h4`
  text-align: center;
  margin: 0;
  padding: 10px 0 0 0;
  line-height: 40px;
  font-family: Roboto, sans-serif;
  @media (min-width: 600px) {
   font-size: 18px;
  }
  @media (min-width: 1024px) {
   font-size: 26px;
  line-height: 56px;
  }
`
const PriceListItem = styled.li`
   ${outOfContainer}
   background-color: ${props => props.transparent ? 'transparent' : color7};
  `
const PriceListItemContainer = styled.div`
     display: flex;
   justify-content: space-between;
`

const mapIndexed = addIndex(map)

const renderPricing = () => mapIndexed(({ title, price }, idx) => {
  let transparent;
  if (idx % 2 === 1) {
    transparent = true
  } else {
    transparent = false
  }
  return <PriceListItem key={title} transparent={transparent}>
    <MaxWidthContainer>
      <PriceListItemContainer>
        <Item>{title} -</Item>
        <PriceContainer>
          <Price>{price}</Price>
        </PriceContainer>
      </PriceListItemContainer>
    </MaxWidthContainer>
  </PriceListItem>
}, prices)

const Pricing = () => {

  return (
    <Wrapper >
      <PricingHeader>Cennik</PricingHeader>
      <PriceList>
        {renderPricing()}
      </PriceList>
      <Caution>
        <MaxWidthContainer>
          <CautionContentContainer>
            <CautionHeaderContainer>
              <CautionHeader>uwaga</CautionHeader>
            </CautionHeaderContainer>
            <CautionSpace />
            <CautionText>
              {cautionText}
            </CautionText>
          </CautionContentContainer>
        </MaxWidthContainer>
      </Caution>
    </Wrapper>
  )
}

export default Pricing
