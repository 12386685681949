export const prices = [
 {
  title: 'Konsultacja',
  price: '30 zł'
 }, {
  title: 'Podstawowy zabieg podologiczny',
  price: 'od 100 zł.'
 }, {
  title: 'Podstawowy zabieg podologiczny stopy cukrzycowej',
  price: 'od 100 zł.'
 }, {
  title: 'Pedicure kosmetyczny',
  price: '100 zł.'
 }, {
  title: 'Pomalowanie paznokci',
  price: '10 zł.'
 }, {
  title: 'Obcięcie i opracowanie paznokci zdrowych',
  price: '40 zł.'
 }, {
  title: 'Oczyszczenie paznokcia zmienionego chorobowo lub pourazowo',
  price: 'od 40 zł.'
 }, {
  title: 'Oczyszczenie i rekonstrukcja płytki paznokcia',
  price: 'od 50 zł.'
 }, {
  title: 'Klamra korygująca Rosa Frasera',
  price: 'od 90 zł.'
 }, {
  title: 'Klamra korygująca metalowa OnyClip',
  price: 'od 80 zł.'
 }, {
  title: 'Przestawienie klamry',
  price: 'od 40 zł.'
 }, {
  title: 'Tamponada',
  price: '10 zł.'
 }, {
  title: 'Usuwanie modzela',
  price: 'od 30 zł.'
 }, {
  title: 'Usuwanie odcisku',
  price: 'od 30 zł.'
 }, {
  title: 'Opracowanie jednej pękającej pięty wraz z opatrunkiem',
  price: 'od 50 zł.'
 }, {
  title: 'Odciążenie (1szt)',
  price: '20 zł.'
 }, {
  title: 'Wycięcie elementu wrastającego paznokcia',
  price: 'od 40 zł.'
 }, {
  title: 'Opatrunek z preparatem (1szt.)',
  price: 'od 10 zł.'
 }, {
  title: 'Usunięcie brodawki wirusowej metodą chemiczną',
  price: 'od 40 zł.'
 }, {
  title: 'Usunięcie brodawki wirusowej metodą elektrokoagulacji',
  price: 'od 80 zł.'
 }, {
  title: 'Pobranie materiału do badania mykologicznego',
  price: '50 zł.'
 },
]

export const cautionText = 'Cennik ma za zadanie przybliżyć orientacyjne ceny usług. Nie jest ofertą w rozumieniu Kodeksu Cywilnego i jest ustalany indywidualnie w zależności od przypadku.'