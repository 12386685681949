import React from 'react'
import styled from 'styled-components'
import { color2, color1, color14 } from '../../constants/colors'
import { headerText, contentText } from '../../content/aboutPodologyText'
import { map } from 'ramda'
import MaxWidthContainer from '../Layout/MaxWidthContainer'

const Header = styled.div`
 color: ${color14};
background-color: ${color2};
 height: 55px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 @media(min-width: 600px){
   height: 75px;
 }
`

const HeaderText = styled.p`
 margin: 0 auto;
 text-align: center;
 font-family: Roboto, sans-serif;
 font-weight: 500;
 font-size: 14px;
 line-height: 140%;
 @media(min-width: 600px){
  font-size: 23px;
 }
  @media(min-width: 1024px){
  font-size: 24px;
 }
`

const ContentWrapper = styled.section`
 background-color: ${color1};
 padding-top: 40px;
 padding-bottom: 30px;
`
const ContentText = styled.p`
 margin: 0 auto 10px;
 text-align: justify;
 font-family: Roboto, sans-serif;
 font-size: 10px;
 line-height: 140%;
  @media(min-width: 600px){
  font-size: 12px;
 }
@media(min-width: 1024px){
  font-size: 20px;
 }
`

const renderHeaderText = () => map(p =>
  <HeaderText key={p}>{p}</HeaderText>
  , headerText)

const renderContentText = () => map(p =>
  <ContentText key={p}>{p}</ContentText>
  , contentText)


const AboutPodology = () => {
  return (
    <>
      <Header>
        {renderHeaderText()}
      </Header>
      <ContentWrapper>
        <MaxWidthContainer>
          {renderContentText()}
        </MaxWidthContainer>
      </ContentWrapper>
    </>
  )
}

export default AboutPodology
