import React from 'react'
import photo from '../../images/urszula-piekarska.jpg'
import styled from 'styled-components'
import { color11 } from '../../constants/colors'

const Photo = styled.div`
  position: relative;
  flex-grow: 1;
  margin: 0 auto;
  width: 150%;
  @media(min-width: 360px){
   width: 130%;
  }
  @media(min-width: 500px){
   width: 70%;
  }
  `

const PhotoCircle = styled.div`
 position: absolute;
 width: 70px;
 height: 70px;
 top: 0;
 left 50%;
 transform: translateX(-65%);
 background: url(${photo}) no-repeat 50% 35%;
 background-size: cover;
 border-radius: 50%;
 box-shadow: 6px 6px 4px rgba(0,0,0,0.25);
 margin-right: 15px;
 @media(min-width: 360px){
 width: 80px;
 height: 80px;
 }
  @media(min-width: 600px) {
   width: 100px;
   height: 100px;
  }
    @media(min-width: 1024px) {
   width: 120px;
   height: 120px;
  }
    @media(min-width: 1280px) {
   width: 150px;
   height: 150px;
  }
`

const Caption = styled.p`
position: absolute;
top: 85%;
left 0;
 font-family: Roboto, sans-serif;
 margin-top: 22px;
 font-size: 8px;
 @media(min-width: 360px){
  font-size: 10px;
 }
 @media(min-width: 600px) {
 font-size: 12px;
  margin-left: 4px;
  }
  @media(min-width: 1024px) {
  font-size: 14px;
  margin-left: 4px;
  top: 90%;
  }
  @media(min-width: 1280px) {
  font-size: 16px;
  margin-left: 10px;
  }
`


const CirclePhoto = () => {
  return (
    <Photo>
      <PhotoCircle>
        <Caption>Urszula Piekarska</Caption>
      </PhotoCircle>
    </Photo>
  )
}

export default CirclePhoto
